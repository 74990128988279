<template>
  <div class="device-alarm-code-manage">
    <!-- 面包屑 -->
    <els-bread class="_bread" :breadColumn="breadColumn"></els-bread>
    <!-- header -->
    <div class="_header">
      <els-button-group
        type="primary"
        size="small"
        :column="buttons"
      ></els-button-group>
      <div class="_search">
        <els-form
          ref="searchRef"
          v-model="searchValue"
          :column="searchColumn"
          :elForm="{ inline: true, size: 'small' }"
        ></els-form>
        <el-button
          style="margin-left: 8px; border: none"
          size="small"
          icon="el-icon-search"
          type="primary"
          @click="handleSearch"
          >搜索</el-button
        >
        <el-button
          style="margin-left: 8px; border: none"
          size="small"
          icon="el-icon-refresh"
          @click="handleReset"
          >重置</el-button
        >
      </div>
    </div>
    <!-- content -->
    <div class="_content">
      <els-table
        ref="elsTableRef"
        :onload="onload"
        :column="tableColumn"
        menuWidth="200px"
      >
        <template v-slot:menu="{ row }">
          <els-button-group
            type="text"
            :column="tableMenus"
            delimiter="divider"
            :params="row"
          ></els-button-group>
        </template>
      </els-table>
    </div>
    <!-- dialog -->
    <els-form-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      :loading="submitLoading"
      @submit="handleSubmit"
      width="800px"
    >
      <els-form
        v-if="dialogVisible"
        ref="elsFormRef"
        class="_dialog-form"
        v-model="formData"
        :column="formColumn"
        :elForm="{ size: 'small' }"
      >
        <template v-slot:upload>
          <el-upload
            drag
            action=""
            :auto-upload="false"
            :on-change="handleUploadOnChange"
            :show-file-list="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              请将文件拖到这里！ <em>点击导入</em>
            </div>
            <div>{{ formData.fileName }}</div>
          </el-upload>
        </template>
      </els-form>
    </els-form-dialog>
  </div>
</template>

<script>
import elsBread from "@/components/els-breadcrumb/main.vue";
import elsTable from "@/components/els-table/main.vue";
import elsDrawer from "@/components/els-drawer/main.vue";
import elsFormDialog from "@/components/els-form-dialog/main.vue";
import elsForm from "@/components/els-form/main.vue";
import elsButtonGroup from "@/components/els-button-group/main.vue";
// import mixinsDicts from "@/mixins/dict.js";
//
import { mapState } from "vuex";
import { saveAs } from "file-saver";
import { downloadFileWithName, downloadFile } from "@/utils/util.js";
//
import {
  getList,
  addData,
  updateData,
  delData,
  detailData,
  importData,
  exportData,
  resetStartOfStop,
} from "@/api/manager/alarmCode.js";
import { getDict } from "@/api/com/iot-dict.js";
import { getDeviceTypeData } from "@/api/manager/device-status-rule.js";

export default {
  // mixins: [mixinsDicts],
  components: {
    elsTable,
    elsDrawer,
    elsBread,
    elsFormDialog,
    elsForm,
    elsButtonGroup,
  },
  data: () => ({
    dicts: {
      ALARM_TYPE: [],
      ALARM_GRADE: [],
      DEVICE_TYPE: [],
    },
    PAGE_NAME: "alarmCodeManage",
    searchValue: {},
    drawerVisible: false,
    dialogVisible: false,
    importVisible: false,
    dialogTitle: "",
    formName: "add",
    formData: {},
    submitLoading: false,
    nodes: undefined,
    currentRow: undefined,
  }),
  computed: {
    breadColumn() {
      return [
        {
          name: "告警规则配置",
          path: "/:lang/hanyun-iot/device-alarm-rule-config",
        },
        {
          name: "告警码管理",
          path: "/:lang/hanyun-iot/device-alarm-code-manage",
        },
      ];
    },
    buttons() {
      const add = { label: "新增告警码", handle: this.handleAdd };
      const input = { label: "导入", handle: this.handleImport };
      const output = { label: "导出", handle: this.handleExport };
      return [add, input, output];
      // return [add];
    },
    searchColumn() {
      const deviceType = {
        prop: "deviceType",
        placeholder: "设备类型",
        tag: "el-select",
        cls: this.dicts.DEVICE_TYPE,
      };
      const alarmType = {
        prop: "alarmType",
        placeholder: "告警类型",
        tag: "el-select",
        cls: this.dicts.ALARM_TYPE,
      };
      const alarmGrade = {
        prop: "alarmGrade",
        placeholder: "告警等级",
        tag: "el-select",
        cls: this.dicts.ALARM_GRADE,
      };
      const alarmCode = { prop: "alarmCode", placeholder: "告警码" };
      return [deviceType, alarmType, alarmGrade, alarmCode];
    },

    tableColumn() {
      const alarmContent = { prop: "alarmContent", label: "告警内容" };
      const alarmCode = { prop: "alarmCode", label: "告警码" };
      const alarmGrade = {
        prop: "alarmGrade",
        label: "告警等级",
        dict: this.dicts.ALARM_GRADE,
      };
      const deviceType = {
        prop: "deviceType",
        label: "设备类型",
        dict: this.dicts.DEVICE_TYPE,
      };
      const alarmType = {
        prop: "alarmType",
        label: "告警类型",
        dict: this.dicts.ALARM_TYPE,
      };
      const alarmSource = { prop: "reason", label: "可能原因" };
      const alarmResult = { prop: "result", label: "可能结果" };
      const alarmAdvice = { prop: "measure", label: "建议措施" };
      const status = {
        prop: "startStatusFlag",
        label: "状态",
        dict: [
          { label: "启用", value: true },
          { label: "停用", value: false },
          { label: "--", value: null },
        ],
      };
      return [
        alarmContent,
        alarmCode,
        alarmGrade,
        deviceType,
        alarmType,
        alarmSource,
        alarmResult,
        alarmAdvice,
        status,
      ];
    },
    tableMenus() {
      const startOrStop = {
        handle: this.handleStartOrStop,
        attrs: (row) => ({
          text: row.startStatusFlag
            ? "停用"
            : "启用" /* icon:row.startStatusFlag ? "el-icon-open" : "el-icon-turn-off" */,
        }),
      };
      const editData = {
        label: "编辑",
        handle: this.handleEdit /* icon:"el-icon-edit" */,
      };
      const deleteData = {
        label: "删除",
        handle: this.handleDelete /* icon:"el-icon-delete" */,
      };
      return [startOrStop, editData, deleteData];
    },
    addFormColumn() {
      const deviceType = {
        prop: "deviceType",
        label: "设备类型",
        tag: "el-select",
        required: true,
      //  elFormItem: { class: "is-block", style: "width:50%;" },
        cls: this.dicts.DEVICE_TYPE,
      };
      const alarmType = {
        prop: "alarmType",
        label: "告警类型",
        tag: "el-select",
        required: true,
        cls: this.dicts.ALARM_TYPE,
      };
      const alarmGrade = {
        prop: "alarmGrade",
        label: "告警等级",
        tag: "el-select",
        required: true,
        cls: this.dicts.ALARM_GRADE,
      };
      const alarmCode = {
        prop: "alarmCode",
        label: "告警码",
        required: true,
        attrs: { maxlength: 30 },
        showWordLimit: true,
      };
      const isRepair = {
        prop: "repairFlag",
        label: "是否需要维修",
        tag: "el-radio-group",
        value: false,
        cls: [
          { label: true, cls: "需要" },
          { label: false, cls: "不需要" },
        ],
      };
      const alarmContent = {
        prop: "alarmContent",
        label: "告警内容",
        elFormItem: { class: "is-block" },
        type: "textarea",
        // autosize: true,
        required: true,
        attrs: { maxlength: 200 },
        showWordLimit: true,
      };
      const alarmSource = {
        prop: "reason",
        label: "可能原因",
        elFormItem: { class: "is-block" },
        type: "textarea",
        // autosize: true,
        attrs: { maxlength: 200 },
        showWordLimit: true,
      };
      const alarmResult = {
        prop: "result",
        label: "可能结果",
        type: "textarea",
        elFormItem: { class: "is-block" },
        // autosize: true,
        attrs: { maxlength: 200 },
        showWordLimit: true,
      };
      const alarmAdvice = {
        prop: "measure",
        label: "建议措施",
        type: "textarea",
        elFormItem: { class: "is-block" },
        // autosize: true,
        attrs: { maxlength: 200 },
        showWordLimit: true,
      };
      const remark = {
        prop: "remark",
        label: "备注",
        type: "textarea",
        elFormItem: { class: "is-block" },
        // autosize: true,
        attrs: { maxlength: 200 },
        showWordLimit: true,
      };
      const isUse = {
        prop: "startStatusFlag",
        label: "是否启用",
        tag: "el-radio-group",
        value: true,
        cls: [
          { label: true, cls: "启用" },
          { label: false, cls: "停用" },
        ],
      };
      const isDiy = {
        prop: "diyFlag",
        label: "是否自定义",
        tag: "el-radio-group",
        value: true,
        cls: [
          { label: true, cls: "是" },
          { label: false, cls: "否" },
        ],
      };
      const isFault = {
        prop: "faultFlag",
        label: "是否故障",
        tag: "el-radio-group",
        value: false,
        cls: [
          { label: true, cls: "是" },
          { label: false, cls: "否" },
        ],
      };
      return [
        deviceType,
        alarmType,
        alarmCode,
        alarmGrade,
        isRepair,
        alarmContent,
        alarmSource,
        alarmResult,
        alarmAdvice,
        remark,
        isUse,
        isDiy,
        isFault,
      ];
    },
    importFormColumn() {
      const upload = {
        tag: "el-form-item",
        prop: "file",
        required: true,
        class:"is-block",
        cls: [{ tag: "slot", name: "upload" }],
      };
      return [upload];
    },
    formColumn() {
      const n = this.formName;
      if (["add", "edit"].includes(n)) {
        return this.addFormColumn;
      }
      if (n == "import") {
        return this.importFormColumn;
      }
    },
  },
  methods: {
    async getList(params) {
      try {
        const res = await getList(params);
        return res.result;
      } catch (e) {
        console.error("获取列表失败！", e);
      }
      return {};
    },
    async addData(data) {
      try {
        const res = await addData(data);
        return res;
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    },
    async updateData(data) {
      try {
        const res = await updateData(data);
        return res;
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    },
    async delData(id) {
      try {
        const res = await delData(id);
        return res;
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    },
    async detailData(id) {
      try {
        const res = await detailData(id);
        return res.result;
      } catch (e) {
        console.error("获取详情失败！", e);
        return Promise.reject(e);
      }
    },
    async importData(d = {}) {
      const { file } = d;
      if (!file) {
        return Promise.reject("请选择文件后再上传！");
      }
      try {
        const data = new FormData();
        data.append("file", file.raw);
        // console.log("====", data, file);
        const res = await importData(data);
        return res;
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    },
    async exportData() {
      try {
        const res = await exportData();
        return res;
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    },
    async resetStartOfStop(id, startStatusFlag) {
      try {
        const res = await resetStartOfStop(id, startStatusFlag);
        return res;
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
    },
    //
    async onload(page) {
      const params = { ...this.searchValue, ...page };
      const data = await this.getList(params);
      const { total = 0, data: list = [] } = data;
      return { total, list };
    },
    async getDeviceType() {
      const res = await getDeviceTypeData();
      this.dicts.DEVICE_TYPE = res.map((item) => ({
        value: item.id.id,
        label: item.deviceTypeName,
      }));
    },
    async getAlertTypeDict() {
      const res = await getDict("alertType");
      this.dicts.ALARM_TYPE = res.map((item) => ({
        // value: `${item.type}_${item.value}`,
        value: item.value,
        label: item.name,
      }));
    },
    async getAlarmGradeDict() {
      const res = await getDict("alarmGrade");
      this.dicts.ALARM_GRADE = res.map((item) => ({
        // value: `${item.type}_${item.value}`,
        value: item.value,
        label: item.name,
      }));
    },
    refreshTable() {
      if (this.$refs.elsTableRef) {
        this.$refs.elsTableRef.apiOnload();
      }
    },
    handleSearch() {
      this.refreshTable();
    },
    handleReset() {
      this.searchValue = {};
      this.refreshTable();
    },
    handleAdd() {
      this.formData = {};
      this.formName = "add";
      this.dialogTitle = "新增告警码";
      this.dialogVisible = true;
    },
    handleImport() {
      // this.importVisible = true;
      this.formData = {};
      this.formName = "import";
      this.dialogTitle = "导入告警码";
      this.dialogVisible = true;
    },
    handleExport() {
      downloadFile(this.exportData,[],'告警码.xlsx');
      // downloadFileWithName(this.exportData, "告警码数据");
      // const res = await this.exportData();
      // console.log(res);
    },
    async handleSubmit() {
      // 校验
      try {
        await this.$refs.elsFormRef.validate();
      } catch (e) {
        return;
      }
      this.submitLoading = true;
      // 调用接口
      const data = { ...this.formData };

      //
      let f = this.addData;
      const fn = this.formName;
      switch (fn) {
        case "add":
          f = this.addData;
          break;
        case "edit":
          f = this.updateData;
          break;
        case "import":
          f = this.importData;
          break;
      }
      // const successMsg = isAdd ? "新增成功！" : "修改成功！";
      try {
        const res = await f(data);
        this.dialogVisible = false;
        this.$message.success(res?.msg);
        this.refreshTable();
      } catch (e) {
        console.error("===== 提交表单失败！====");
        console.error(e);
      }
      this.submitLoading = false;
    },
    async handleStartOrStop({ params: row }) {
      const { startStatusFlag, id } = row;
      if (id == undefined) {
        return;
      }
      const res = await this.resetStartOfStop(id, !startStatusFlag);
      this.$message.success(res?.msg);
      this.refreshTable();
    },
    async handleEdit({ params: row }) {
      const id = row.id;
      const data = await this.detailData(id);
      this.formName = "edit";
      this.formData = data;
      this.dialogTitle = "编辑告警码";
      this.dialogVisible = true;
    },
    async handleDelete({ params: row }) {
      const id = row.id;
      this.$confirm("确认是否删除这条记录?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.delData(id);
          if (res == undefined) {
            return;
          }
          this.$message.success(res?.msg);
          this.refreshTable();
        })
        .catch(() => {});
    },
    handleUploadOnChange(file) {
      this.formData = { file, fileName: file.name };
    },
  },
  created() {
    this.getDeviceType();
    this.getAlertTypeDict();
    this.getAlarmGradeDict();
  },
};
</script>

<style lang="scss">
.device-alarm-code-manage {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 8px;
  ._header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ._search {
      display: flex;
      align-items: center;
      .el-form-item {
        margin: 0 4px;
      }
    }
  }
  ._content {
    position: relative;
    width: 100%;
    height: calc(100% - 44px - 44px);
  }
  ._subtable-content {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 0 12px;
  }
  .el-icon-open {
    color: #67c23a;
  }
  .el-icon-turn-off {
    color: #ccc;
  }
}

._dialog-form {
  .el-form {
    > div {
      margin-right: 0;
      width: 50%;
      display: inline-block;
      padding: 0 16px;
      box-sizing: border-box;
      &.is-block {
        display: block;
        width: 100%;
      }
      .el-select {
        width: 100%;
      }
      .el-upload{
        display:block;
      }
      .el-upload-dragger{
        width:auto;
      }
    }
  }
}
</style>
<style scoped lang="scss">
::v-deep .els-button-group .el-button--text:nth-child(5) {
        color: red;
    }
</style>
