import http from "@/axios/index"
import { URI } from "@/config/axios"

// 获取列表 /api/alarmCode/queryPageList{?pageNum,pageSize,deviceType,alarmType,alarmGrade,alarmCode}
export const getList = (params = {}) => {
  return http({
    url: URI.system + 'api/alarmCode/queryPageList',
    method: 'get',
    params
  })
}

// 新增 /api/alarmCode/addAlarmCode
export const addData = (data = {}) => {
  return http({
    url: URI.system + 'api/alarmCode/addAlarmCode',
    method: 'post',
    data
  })
}

// 修改 /api/alarmCode/updateAlarmCode
export const updateData = (data = {}) => {
  return http({
    url: URI.system + 'api/alarmCode/updateAlarmCode',
    method: 'post',
    data
  })
}

// 删除 /api/alarmCode/deleteAlarmCodeById{?id}
export const delData = (id) => {
  return http({
    url: URI.system + 'api/alarmCode/deleteAlarmCodeById',
    method: 'delete',
    params:{id}
  })
}

// 详情 /api/alarmCode/queryById{?id}
export const detailData = (id) => {
  return http({
    url: URI.system + 'api/alarmCode/queryById',
    method: 'get',
    params:{id}
  })
}

// 导入 /api/alarmCode/addBatchData
export const importData = (data = {},progress) => {
  return http({
    url: URI.system + 'api/alarmCode/import',
    method: 'post',
    data,
    onUploadProgress: (e) => {
      progress?.(e);
    },
  })
}

// 导出 /api/alarmCode/getBatchData
export const exportData = (params = {},progress) => {
  return http({
    url: URI.system + 'api/alarmCode/export',
    method: 'get',
    responseType: "blob",
    params,
    // onDownloadProgress: (e) => {
    //   progress?.(e);
    // },
  })
}

// 启用|停用 /api/alarmCode/updateStartStatusFlag{?id,startStatusFlag}
export const resetStartOfStop = (id,startStatusFlag) => {
  return http({
    url: URI.system + 'api/alarmCode/updateStartStatusFlag',
    method: 'put',
    params:{id,startStatusFlag}
  })
}

// 通过设备类型 查询 告警码 GET /api/alarmCode/queryByDeviceType
export const getDataByDeviceType = (deviceType,alarmTypes) => {
  return http({
    url: URI.system + 'api/alarmCode/queryByDeviceTypeAndAlarmType',
    method: 'get',
    params:{deviceType,alarmTypes}
  })
}
