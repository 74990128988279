import http from "@/axios/index"
import { URI } from "@/config/axios"

// 获取设备状态规则列表
export const getStateRuleList = (params = {}) => {
    return http({
        url: URI.system + `api/stateRule/pageRule`,
        method: 'get',
        params: {
            ...params
        }
    })
}

// 获取所有设备类型
export const getDeviceTypeData = () => {
    return http({
        url: URI.system + `api/tenant/findByTenantId`,
        method: 'get'
    })
}

// 根据设备类型获取设备名称
export const getDeviceNames = (params = {}) => {
    return http({
        url: URI.system + `api/deviceType/getDeviceNames`,
        method: 'get',
        params: {
            ...params
        }
    })
}


// 根据设备类型获取设备点位
export const getDeviceNodes = (params = {}) => {
    return http({
        url: URI.system + `api/deviceTypeNode/getDeviceNodes`,
        method: 'get',
        params: {
            ...params
        }
    })
}


// 新增设备状态规则配置
export const addStateRule = (params = {}) => {
    return http({
        url: URI.system + `api/stateRule/addRule`,
        method: 'post',
        data: params
    })
}

// 编辑设备状态规则配置
export const updateStateRule = (params = {}) => {
    return http({
        url: URI.system + `api/stateRule/updateRule`,
        method: 'post',
        data: params
    })
}


// 删除设备状态规则配置
export const delStateRule = (id) => {
    return http({
        url: URI.system + `api/stateRule/deleteRule`,
        method: 'delete',
        params: {id:id}
    })
}

// 获取设备状态维护树列表
export const getStateList = (params = {}) => {
    return http({
        url: URI.system + `api/stateRule/treeDeviceStatus`,
        method: 'get',
        params: {
            ...params
        }
    })
}

// 新增设备状态字典项
export const addDeviceStatus = (params = {}) => {
    return http({
        url: URI.system + `api/stateRule/addDeviceStatus`,
        method: 'post',
        data: params
    })
}

// 编辑设备状态字典项
export const updateDeviceStatus = (params = {}) => {
    return http({
        url: URI.system + `api/stateRule/updateDeviceStatus`,
        method: 'post',
        data: params
    })
}

// 删除设备状态字典项
export const deleteDeviceStatus = (id) => {
    return http({
        url: URI.system + `api/stateRule/deleteDeviceStatus`,
        method: 'delete',
        params: {id}
    })
}

// 查询设备状态字典
export const getStateDict = () => {
    return http({
        url: URI.system + `api/stateRule/pageDeviceStatus`,
        method: 'get',
        params: {
            pageNum: 1,
            pageSize: 99999
        }
    })
}